import './App.css';
import { Container, Navbar, Nav, Button, Card, Dropdown, Modal, Form, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { useEffect, useState } from 'react';
import abi from "./constants/abi.json"
import erc20Abi from "./constants/erc20_abi.json"
import { default as addressesJson } from "./constants/addresses.json"
import { default as poolsTickers } from "./constants/pools.json"
import { ethers } from 'ethers';
import { wait } from '@testing-library/user-event/dist/utils';

const EXPLORER_PREFIX = "https://songbird-explorer.flare.network/address/"
const ALLOWED_CHAIN_IDS = ['19', '16']

const navLinkStyle = {
    color: "#fff",
    fontSize: 17
}

function App() {

    const [account, setAccount] = useState("")
    const [chainId, setChainId] = useState("")
    const [provider, setProvider] = useState(null)
    const [signer, setSigner] = useState(null)
    const [contract, setContract] = useState(null)

    const [pools, setPools] = useState([])
    const [currentPoolInfo, setCurrentPoolInfo] = useState({})
    const [currentPoolTicketVal, setCurrentPoolTicketVal] = useState(0)
    const [unclaimedRewards, setUnclaimedRewards] = useState(0)

    const [joinModalShow, setJoinModalShow] = useState(false)
    const [modalTicketVal, setModalTicketVal] = useState(0)
    const [approvedTokens, setApprovedTokens] = useState(false)
    const handleClose = () => {
        setApprovedTokens(false)
        setJoinModalShow(false)
    }
    const handleOpen = () => setJoinModalShow(true)

    const [secondsLeftPool, setSecondsLeftPool] = useState(0)

    useEffect(() => {
        if (secondsLeftPool > 0) {
            setTimeout(() => {
                setSecondsLeftPool(secondsLeftPool - 1)
            }, 1000);
        }
    })

    const connectWallet = async () => {
        if (window.ethereum) {
            if (!(ALLOWED_CHAIN_IDS.includes(window.ethereum.networkVersion))) {
                alert("Switch your network to Songbird or Coston")
                return
            }

            window.ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
                if (res.length > 0) {
                    let newAcc = res[0]
                    accountChanged(newAcc)
                } else {
                    alert("No accounts found in your MetaMask")
                }
            })

        } else {
            alert("Please install MetaMask before connecting your wallet.")
        }
    }

    const accountChanged = (newAccount) => {
        setAccount(newAccount)
        loadEthersProperties()
    }

    const loadEthersProperties = () => {
        let tempChainId = window.ethereum.networkVersion
        setChainId(tempChainId);

        let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(tempProvider);

        let tempSigner = tempProvider.getSigner();
        setSigner(tempSigner);

        const networkAddr = tempChainId === "16" ? addressesJson.addresses.coston : addressesJson.addresses.songbird
        let tempContract = new ethers.Contract(networkAddr, abi, tempSigner);
        setContract(tempContract);

        const networkName = tempChainId === "16" ? "coston" : "songbird"
        setPools(poolsTickers[networkName])
    }

    const setPoolInfo = async (pool) => {
        let poolData = await contract.pools(pool)
        setCurrentPoolInfo(poolData)
        setCurrentTicketValue(pool)
        setSecondsLeftPool(Math.round((poolData.startDate + poolData._length - Date.now()) / 1000))
        handleUnclaimedRewards(pool)
    }

    const setCurrentTicketValue = async (ticker) => {
        let val = await contract.calculateTicketWorthAsCurrency(5, ticker)
        setCurrentPoolTicketVal(parseInt(ethers.utils.formatEther(val)));
    }

    const handleModalTicketValChange = (event, min, max) => {
        const value = Math.max(min, Math.min(max, Number(event.target.value)));
        setModalTicketVal(value);
    };

    const handleUnclaimedRewards = async (ticker) => {
        let reward = await contract.unclaimedRewards(ticker, account)
        setUnclaimedRewards(parseInt(ethers.utils.formatEther(reward)))
    }

    /* const remainingTimeHandler = () => {
        var date = new Date(0);
        date.setSeconds(secondsLeftPool);
        var timeString = date.toISOString().substr(11, 8);
        return timeString
    } **/

    const approveTokens = async () => {
        try {
            let tknContract = new ethers.Contract(currentPoolInfo.token, erc20Abi, signer)
            let tx = await tknContract.approve(contract.address, ethers.utils.parseEther((modalTicketVal * currentPoolTicketVal).toString()))
            await wait(tx)
            alert("Approved tokens!")
            setApprovedTokens(true)
        } catch (err) {
            setApprovedTokens(false)
            if ("reason" in err) {
                alert("There was a problem approving tokens.\nReason: " + err.reason.replace("execution reverted: ", ""))
                return
            }

            alert("There was a problem approving tokens.\nReason: " + err.message)
        }
    }

    const joinPool = async () => {
        try {
            let joinTx = await contract.joinPool(currentPoolInfo.ticker, modalTicketVal)
            const joinReceipt = await wait(joinTx)
            alert("Joined pool!")
        } catch (err) {
            if ("reason" in err) {
                alert("There was a problem joining the pool.\nReason: " + err.reason.replace("execution reverted: ", ""))
                return
            }

            alert("There was a problem joining the pool.\nReason: " + err.message)
        }
    }

    const claimRewards = async () => {
        try {
            let tx = await contract.claimRewardsFromPool(currentPoolInfo.ticker)
            const receipt = await wait(tx)
            alert("Claimed rewards!")
        } catch (err) {
            if ("reason" in err) {
                alert("There was a problem while claiming rewards.\n" + err.reason)
                return
            }
            alert("There was a problem while claiming rewards.\nReason: " + err.message)
        }
    }

    window.ethereum.on('accountsChanged', () => {
        connectWallet()
    })

    window.ethereum.on('chainChanged', () => {
        window.location.reload()
    })

    return (
        <div className="App">
            <Navbar bg="dark" expand="lg" expanded>
                <Container>
                    <Navbar.Brand>
                        <img src="brand.png" alt="CPNK Brand Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="https://canarypunks.xyz"
                                target="_blank"
                                rel="noopener noreferrer" style={navLinkStyle}>About</Nav.Link>
                            {/*<Nav.Link href="https://stake.canarypunks.xyz"
                                target="_blank"
                                rel="noopener noreferrer" style={navLinkStyle}>NFT Staking</Nav.Link>*/}
                            <Nav.Link href="https://animated-raindrop-74f25a.netlify.app/"
                                target="_blank"
                                rel="noopener noreferrer" style={navLinkStyle}>Get TCGLD</Nav.Link>
                        </Nav>

                        <Nav color="#fff">
                            {account && (<Nav.Link
                                href={(chainId === "19" ? EXPLORER_PREFIX : EXPLORER_PREFIX.replace("songbird", "coston")) + account}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#fff', 'marginRight': '10px', fontWeight: 500 }}>
                                <img
                                    src={window.ethereum.networkVersion === "19" ? "songbird.png" : "coston.png"}
                                    alt="Network"
                                    width="36"
                                    height="36"
                                    style={{ "borderRadius": "5px", marginRight: 8 }} />
                                {account && account.toString().substring(0, account.length / 4) + "..."}
                            </Nav.Link>)}
                            <Button id="connectBtn" onClick={connectWallet}>{account ? 'Connected' : 'Connect Wallet'}</Button>
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <header className='App-header'>
                <Row>
                    <Col lg={true}>
                        <Card bg='dark' className="shadow" style={{ width: "20rem", border: "0" }}>
                            <Card.Header style={{ "fontWeight": "bolder", background: "#01aeee", color: "#292b2c" }}>HOW TO PLAY</Card.Header>
                            <Card.Body className="mb-2">
                                <Card.Text style={{ fontSize: 15 }}>
                                    <span style={{ fontWeight: 500 }}>Welcome to the Canary Punk Raffle!</span><br />

                                    Each pool lasts 1 day and a winning ticket is randomly drawn at the end of each raffle.<br /><hr />
                                    Currently on $WSGB, 1 ticket = 5 dollars worth of WSGB at the time<br /><hr />
                                    $CGLD and partnering project tokens are 1 token = 1 ticket<br /><hr />
                                    Only 100 tickets can be purchased, per wallet<br /><hr />
                                    When the pool ends, a random ticket is drawn and is rewarded with the prize pool<br />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={true}>
                        <Card bg='dark' className="shadow" style={{ width: "20rem", border: "0" }}>
                            <Card.Header style={{ "fontWeight": "bolder", background: "#01aeee", color: "#292b2c" }}>RAFFLE</Card.Header>
                            <Card.Body className="mb-2">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" style={{ width: "200px" }}>
                                        {account ? 'Select a pool' : 'Connect your wallet first'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                            account && pools.map((pool, key) => {
                                                return (
                                                    <Dropdown.Item key={key} onClick={() => setPoolInfo(pool)}>{pool}</Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                {account && currentPoolInfo.ticker &&
                                    <Container>
                                        <Card.Title className="mt-3" style={{ fontSize: 30, fontWeight: 600 }}>
                                            Pool Info
                                            <hr />
                                        </Card.Title>
                                        <Card.Text className="cardTextPoolInfo">
                                            {"Ticker: " + currentPoolInfo.ticker}
                                        </Card.Text>
                                        <Card.Text className="cardTextPoolInfo">
                                            {"Status: " + (currentPoolInfo.started ? "🟢" : "🔴")}
                                        </Card.Text>
                                        {currentPoolInfo.started && (
                                            <>
                                                <Card.Text className="cardTextPoolInfo">{"Total value: " + currentPoolInfo.ticketsBought * currentPoolTicketVal + " " + currentPoolInfo.ticker}</Card.Text>
                                                {/**<Card.Text className="cardTextPoolInfo">{remainingTimeHandler() + " remaining"}</Card.Text>*/}
                                                <Button id="connectBtn" onClick={handleOpen}>Join Pool</Button>
                                                <br />
                                            </>
                                        )}
                                        <hr />
                                        <Card.Text className="cardTextPoolInfo">{`Unclaimed rewards: ${unclaimedRewards} ${currentPoolInfo.ticker}`}</Card.Text>
                                        <Button id="connectBtn" onClick={claimRewards}>Claim Rewards</Button>
                                    </Container>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Modal className="joinModal" show={joinModalShow} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{`Join ${currentPoolInfo.ticker} Pool`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {`Current ticket price: ${currentPoolTicketVal} ${currentPoolInfo.ticker}`}
                        <Form>
                            <Form.Group>
                                <Form.Label>Tickets:</Form.Label>
                                <Form.Control min="0" max={`${currentPoolInfo.maxTicketPerParticipant}`}
                                    value={modalTicketVal}
                                    onChange={(event) => { handleModalTicketValChange(event, 0, currentPoolInfo.maxTicketPerParticipant) }}
                                    type="number" autoFocus />
                                <Form.Label style={{
                                    fontSize: 16, color: "#000", background: "#fff", borderRadius: "5px", marginTop: "5px",
                                    boxShadow: "2px 2px 3px #01aeee"
                                }}>
                                    <span onClick={() => setModalTicketVal(currentPoolInfo.maxTicketPerParticipant)} style={{ fontWeight: 'bold', padding: "3px 3px 3px 3px" }}>
                                        MAX: <span style={{ fontWeight: 400 }}>{`${currentPoolInfo.maxTicketPerParticipant}`}
                                        </span>
                                    </span>
                                </Form.Label>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                        <Button disabled={approvedTokens} type="submit" id="connectBtn" onClick={() => {
                            approveTokens()
                        }}>
                            Approve
                        </Button>
                        <Button disabled={!approvedTokens} type="submit" id="connectBtn" onClick={() => {
                            handleClose()
                            joinPool()
                        }}>
                            Join Pool
                        </Button>
                    </Modal.Footer>
                </Modal>
            </header>
        </div >
    );
}

export default App;
